






























































































.image-viewer__copies {
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  width: 100%;

  .approval_form {
    flex: 1 0 100%;
  }
}
