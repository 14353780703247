



















































































































































@import "~vars";

.drop-table-cell {
  background-color: $grey-light;
  padding: 0 !important;
  .copy-dropzone {
  text-align: center;
  }
}
.dragcopy_buttons{
  display: flex;
}
#blancBtn{
  height: 3rem;
  width: 100%;
}

tbody.file-row {
  border-top: 1px solid $grey;

  td {
    border-bottom: none;
  }
}

.copy-dropzone {
  position: relative;
  &.is-empty {
    background-color: $grey-light;
    &::before {
      display: block;
      color: $grey;
      text-shadow: 1px 1px #fff;
      padding-top: 1rem;
      padding-left: 1rem;
    }

    &.is-one-sided::before {
      content: "Koppel uw bestand hier";
      position: absolute;
      top: 0;
      left: 0;
    }

    &.is-two-sided::before {
      content: "Koppel EERST uw bestand VOORZIJDE";
    }

    &.is-two-sided::after {
      content: "Koppel VERVOLGENS uw bestand ACHTERZIJDE";
      display: block;
      color: $grey;
      text-shadow: 1px 1px #fff;
      padding-top: 1rem;
      padding-left: 1rem;
    }
  }
}
