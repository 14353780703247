

































































































































































































































































































































































































































































































































































































































































































































































































































.card{
  margin-bottom: 0.5rem;
  .subtitle{
    .leftbox{
      min-width: fit-content !important;
      padding-right: 5%;

    }
    .rightbox{
      min-width: 0 !important;
      width: 40%;
      font-size: small;
    }
  }
}

.finish-product{
  font-size: small;
  min-width: 13rem;
}
.finish-zijde{
  font-size: small;
  text-align: right;
  max-width: 5rem;
  min-width: 5rem;
  margin-right: 2rem
}
.prijs-opbouw{
  font-size: small;
  text-align: right;
  max-width: 5rem;
  min-width: 5rem;
  margin-right: 1rem;
}
.prijs-beschrijving{
  font-size: small;
  min-width: 10rem;
}
.mobile-price{
  display: flex;
  flex-direction: column;
   
}
