
































































@import "~vars";
.file-item {
  padding: 0.35em 0.5em !important;
  &.linked {
    background-color: $cyan;
    color: #fff;
    .trash{
      background-color: $cyan;
    }
  }

  &.unlinked {
    background-color: $grey-lightest;
    border-bottom: 1px solid $grey-lighter;
    .trash{
      background-color: $grey-lightest;
    }
  }

  &.suggestion {
    background-color: $background-yellow;
    .trash{
      background-color: $background-yellow;
    }
  }
}

.file-item__content {
  font-size: 0.9rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 2.3rem;
  cursor: move;
  padding: 0.5rem;
}


.trash{
  cursor: pointer;
}

.file-item__dimensions {
  font-style: italic;
  width: 8rem;
  display: inline-block;
}

.file-item__name {
  margin-left: 1rem;
}
