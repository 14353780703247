



















































































































































































































































@import "~vars";

.quick-edit{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: rgb(229 231 235 / 1);
  align-items: center;
}
.image-director {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  max-height: 100%;
  overflow-y: scroll;

  &__body {
    display: flex;
    max-height: 100%;
    overflow-y: hidden;
    flex: 1 0 auto;
    //  mobile and tablet
    @media (max-width: 1675px) {
      display: flex;
      flex-direction: column;
      flex: 1 0 auto;
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;

    .subtitle {
      margin-bottom: 0;
    }
  }
  .blanc{
    padding-top: 2rem;
  }

  .todo__files,
  .copies__files {
    overflow-y: scroll;
    max-height: 100%;
    flex: 1 0 auto;
    padding: 1rem;
    @media (max-width: 1675px) {
      max-height: 12rem;
      max-width: 100%;
    }
  }
 
  .copies__files {
    max-width: 60%;
    //  mobile and tablet
    @media (max-width: 1675px) {
      max-width: 100%;
    }
  }

  .todo__label {
    padding: 0.3rem;
    display: block;
    border-bottom: 2px solid $grey-lighter;
    line-height: 1.2;
  }
}

.attached-legenda {
  p{
    padding-bottom: 0.5rem;
  }
  align-items: center;

  .legenda-item {
    display: inline-flex;
    align-items: center;
    &::before {
      content: "";
      width: 1rem;
      height: 1rem;
      display: inline-block;
      margin-left: 0.5rem;
      margin-right: 0.25rem;
    }

    &__attached::before {
      background-color: $cyan;
    }
    &__suggested::before {
      background-color: $background-yellow;
    }
  }
}
