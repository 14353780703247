






























































@use "sass:color";
@import "~vars";

$baseSize: 2rem;
$maxCopyWidth: 7.5rem;

.image-viewer__copy {
  width: 30%;
  margin-right: 3%;
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 0 0 30%;
  padding-right: $baseSize;
  @media screen and (max-width: 768px) {
    flex: 0 0 100%;

  }

  .copy__width {
    position: absolute;
    right: $baseSize;
    line-height: $baseSize;
    padding: 0 0.5rem;
    max-width: $maxCopyWidth;
  }

  .copy__quantity {
    position: absolute;
    right: 0;
    line-height: $baseSize;
    width: $baseSize;
    text-align: center;
    background-color: $primary;
    color: $primary-invert;
  }

  .copy__image {
    position: relative;
  }

  .copy__image-turn {
    position: absolute;
    bottom: 0;
    right: 0;
    border: none;
    background-color: transparent;
    height: $baseSize;
    width: $baseSize;
    padding: 0;
    line-height: $baseSize;
    font-size: 0.8rem;

    &:focus {
      outline: none;
      box-shadow: none;
    }

    &-icon {
      position: absolute;
      width: $baseSize * 0.8;
      top: 50%;
      left: 50%;
      height: $baseSize * 0.8;
      transform: translate(-50%, -50%) rotate(0deg);
      transform-origin: 50% 50%;
      transition: transform 0.2s ease-in-out;

      &.is-rotate {
        transform: translate(-50%, -50%) rotate(-180deg);
      }
    }
  }

  .copy__title {
    line-height: $baseSize;
    max-width: calc(100% - #{$baseSize + $maxCopyWidth});
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .copy__height {
    writing-mode: vertical-lr;
    position: absolute;
    right: 0;
    line-height: $baseSize;
    top: $baseSize;
    padding: 0.5rem 0;
  }

  .copy__height,
  .copy__width {
    background-color: $info;
    color: $info-invert;
  }

  .is-disapproved {
    .copy__image::after {
      content: attr(data-reason);
      position: absolute;
      top: $baseSize;
      left: 0;
      width: 100%;
      height: calc(100% - #{$baseSize});
      color: $danger-invert;
      font-size: $size-3;
      background-color: rgba($danger, 0.6);
      overflow-wrap: anywhere;
    }
  }

  .field__approval {
    margin-bottom: 0;

    .input {
      border-left: 1px solid $primary;
      border-right: 1px solid $primary;
    }
  }

  .image-viewer__side:not(:first-of-type) {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    padding-right: $baseSize;
    visibility: hidden;

    &.show {
      visibility: visible;
    }
  }
}
