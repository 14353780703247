






































































.invoice-lines {
  max-width: 800px;
}
