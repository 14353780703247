







































.extra-wrapper {
  flex: 0 0 100%;

  .extra-files {
    display: flex;
    flex: 0 0 100%;
    flex-wrap: wrap;
    margin-top: 1rem;

    .extra__title {
      flex: 0 0 100%;
    }

    .extra-files__image {
      flex: 0 0 30%;
      margin-right: 2rem;
      @media screen and (max-width: 768px) {
        flex: 0 0 100%;
      }
    }
  }
}
