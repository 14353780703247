

















































































@import "~vars";

.invoie-line-price td {
  p{
  width: 50px;
  text-align: right;
  } 
}

