








































@import "~vars";
.empty-text {
  color: $magenta;
}
