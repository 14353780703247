




















































.activities__view .icon {
  margin-right: 0.5rem;
}
