


































































































@import "~vars";

.img-container {
  position: relative;

  .loader {
    position: absolute;
    right: -1.75rem;
    bottom: 0;
    height: 1.5rem;
    width: 1.5rem;
    border-left-color: $magenta;
    border-bottom-color: $magenta;
  }
}

.file_image {
  max-width: 100%;
  max-height: 100%;
  width: webkit-fit-content !important;
  height: webkit-fit-content !important;
  vertical-align: middle;
  cursor: -moz-zoom-in; 
  cursor: -webkit-zoom-in; 
  cursor: zoom-in;
  
}

@keyframes placeHolderShimmer{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}
